<template>
  <Dialog :common-dialog="dialog" :dialog-width="dialogWidth">
    <template v-slot:title>
      <v-layout class="px-4">
        <v-flex> Select a Customer </v-flex>
        <v-flex class="text-right">
          <v-btn
            style="height: 32px !important"
            class="custom-bold-button"
            depressed
            icon
            :disabled="loading"
            v-on:click="$emit('close', true)"
            color="red white--text"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:body>
      <v-tabs
        active-class="custom-tab-active"
        v-model="clientTab"
        background-color="transparent"
        color="cyan"
        class="custom-tab-transparent tab-sticky"
        show-arrows
      >
        <v-tab class="font-size-16 font-weight-600 px-8" href="#customer-list">
          <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
            <!--begin::Svg Icon-->
            <inline-svg :src="$assetURL('media/custom-svg/customer.svg')" />
            <!--end::Svg Icon-->
          </span>
          Select Customer
        </v-tab>
        <v-tab class="font-size-16 font-weight-600 px-8" href="#supplier-list">
          <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
            <!--begin::Svg Icon-->
            <inline-svg :src="$assetURL('media/custom-svg/customer.svg')" />
            <!--end::Svg Icon-->
          </span>
          Select Supplier
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="clientTab">
        <v-tab-item value="customer-list">
          <div class="px-4 w-100">
            <label for="customer-search" class="font-weight-600"
              >Which customer would you like to select this for?</label
            >
            <div class="d-flex mb-2">
              <v-text-field
                id="customer-search"
                v-model="search"
                class="line-item-header-text px-0 v-slot-m-none"
                placeholder="Search Customers..."
                dense
                filled
                solo
                flat
                color="cyan"
                :loading="loading"
                :disabled="loading"
                style="max-width: 400px"
              />
              <v-btn
                v-on:click="getCustomers()"
                :loading="loading"
                :disabled="loading"
                style="height: 32px !important"
                class="custom-bold-button ml-2"
                depressed
                color="cyan white--text"
                >Search</v-btn
              >
            </div>
            <div
              class="custom-border-top"
              style="max-height: calc(100vh - 300px); overflow-y: scroll"
            >
              <div v-if="list.length">
                <div
                  v-for="(row, index) in list"
                  :key="`customer-list-key-${index}`"
                  class="cursor-pointer hover-grey d-flex align-center custom-border-bottom py-3 px-3"
                  v-on:click="selectCustomer(row, 'customer-list')"
                >
                  <div style="width: 80%">
                    <p class="m-0 font-weight-500 font-size-16">
                      <Barcode :barcode="row.barcode" />
                      <v-chip
                        color="blue white--text"
                        label
                        small
                        class="ml-1"
                        style="height: 26px"
                        v-if="row.company_type == 'company'"
                      >
                        <span class="font-size-16 font-weight-500">
                          Company
                        </span>
                      </v-chip>
                      <v-chip
                        color="red white--text"
                        label
                        class="ml-1"
                        style="height: 26px"
                        v-if="row.company_type == 'personal'"
                      >
                        <span class="font-size-16 font-weight-500">
                          Individual
                        </span>
                      </v-chip>
                    </p>
                    <p class="m-0 font-weight-500 font-size-16">
                      <v-icon class="chip-custom-blue mr-2" small
                        >mdi-bag-checked</v-icon
                      >{{ row.company_name }}
                    </p>
                    <p class="m-0 font-weight-500 font-size-16">
                      <v-icon class="chip-custom-blue mr-2" small
                        >mdi-account</v-icon
                      >{{ row?.default_person?.display_name }}
                    </p>
                    <p class="m-0 font-weight-500 font-size-16">
                      <v-icon class="chip-custom-blue mr-2" small
                        >mdi-map-marker-outline</v-icon
                      >{{ row?.single_billing?.street_1 }}
                      {{ row?.single_billing?.unit_no }}
                      {{ row?.single_billing?.street_2 }}
                      <template
                        v-if="
                          row?.single_billing?.zip_code &&
                          row.single_billing.zip_code != '000000'
                        "
                      >
                        {{ row?.single_billing?.zip_code }}
                      </template>
                    </p>
                  </div>
                  <div style="width: 20%">
                    <p class="m-0 font-weight-500 font-size-16">
                      {{ row.property_count }} Site Location(s)
                    </p>
                    <p class="m-0 font-weight-500 font-size-16">
                      {{ row.persons_count }} Contact Person(s)
                    </p>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="d-flex justify-center align-center py-3 font-weight-500"
              >
                <img
                  width="40px"
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image mr-2"
                />
                Uhh... There are no customer at the moment.
              </div>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item value="supplier-list">
          <div class="px-4 w-100">
            <label for="customer-search" class="font-weight-600"
              >Which supplier would you like to select this for?</label
            >
            <div class="d-flex mb-2">
              <v-text-field
                id="supplier-search"
                v-model="search"
                class="line-item-header-text px-0 v-slot-m-none"
                placeholder="Search Suppliers..."
                dense
                filled
                solo
                flat
                color="cyan"
                :loading="loading"
                :disabled="loading"
                style="max-width: 400px"
              />
              <v-btn
                v-on:click="getCustomers()"
                :loading="loading"
                :disabled="loading"
                style="height: 32px !important"
                class="custom-bold-button ml-2"
                depressed
                color="cyan white--text"
                >Search</v-btn
              >
            </div>
            <div
              class="custom-border-top"
              style="max-height: calc(100vh - 300px); overflow-y: scroll"
            >
              <div v-if="list.length">
                <div
                  v-for="(row, index) in list"
                  :key="`customer-list-key-${index}`"
                  class="cursor-pointer hover-grey d-flex align-center custom-border-bottom py-3 px-3"
                  v-on:click="selectCustomer(row, 'supplier-list')"
                >
                  <div style="width: 80%">
                    <p class="m-0 font-weight-500 font-size-16">
                      <v-icon class="chip-custom-blue mr-2" small
                        >mdi-bag-checked</v-icon
                      >{{ row.company_name }}
                    </p>
                    <p class="m-0 font-weight-500 font-size-16">
                      <v-icon class="chip-custom-blue mr-2" small
                        >mdi-account</v-icon
                      >{{ row?.default_person?.display_name }}
                    </p>
                    <p class="m-0 font-weight-500 font-size-16">
                      <v-icon class="chip-custom-blue mr-2" small
                        >mdi-map-marker-outline</v-icon
                      >{{ row?.single_billing?.street_1 }}
                      {{ row?.single_billing?.unit_no }}
                      {{ row?.single_billing?.street_2 }}
                      <template
                        v-if="
                          row?.single_billing?.zip_code &&
                          row.single_billing.zip_code != '000000'
                        "
                      >
                        {{ row?.single_billing?.zip_code }}
                      </template>
                    </p>
                  </div>
                  <div style="width: 20%">
                    <p class="m-0 font-weight-500 font-size-16">
                      {{ row.property_count }} Site Location(s)
                    </p>
                    <p class="m-0 font-weight-500 font-size-16">
                      {{ row.persons_count }} Contact Person(s)
                    </p>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="d-flex justify-center align-center py-3 font-weight-500"
              >
                <img
                  width="40px"
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image mr-2"
                />
                Uhh... There are no supplier at the moment.
              </div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </Dialog>
</template>
    
    <script>
import { mapGetters } from "vuex";
import Dialog from "@/view/pages/partials/Dialog.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";
import { QUERY } from "@/core/services/store/request.module";
import {
  SET_TO_CUSTOMER,
  SET_TO_CUSTOMER_TYPE,
} from "@/core/services/store/delivery.module";
import ObjectPath from "object-path";

export default {
  name: "select-customer",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      clientTab: "customer-list",
      loading: false,
      search: null,
      list: [],
    };
  },
  watch: {
    dialog(param) {
      if (param) {
        this.search = null;
        this.getCustomers();
        this.clientTab = this.vToCustomerType;
      }
    },
    clientTab(param) {
      if (param) {
        this.search = null;
        this.getCustomers();
      }
    },
  },
  components: {
    Dialog,
    Barcode,
  },
  methods: {
    selectCustomer(row, type) {
      if (this.loading) {
        return false;
      }
      this.$store.commit(SET_TO_CUSTOMER_TYPE, type);
      this.loading = true;

      this.$store
        .dispatch(SET_TO_CUSTOMER, row)
        .then(() => {
          this.$emit("close", true);
          this.search = null;
          this.list = [];
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCustomers() {
      this.loading = true;
      this.list = [];
      this.$store
        .dispatch(QUERY, {
          url: this.clientTab,
          data: { search: this.search },
        })
        .then((response) => {
          this.list = ObjectPath.get(response, "data", []);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    ...mapGetters(["vToCustomerType"]),
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 50);
    },
  },
};
</script>
    